window.addEventListener("load", (event) => {
    const inputProductsHTML = document.querySelector("#mandant_product_name");
    const matieresHTML = document.querySelector("#mandant_raw_material");
    const gradesHTML = document.querySelector("#mandant_category");

    //Pour conserver les grades des matières
    const cache = {};


    //Permet de créer les options des selects
    const createSelectOption = (value, text) => {
        const option = document.createElement("option");
        option.value = value;
        option.textContent = text;
        return option;
    };

    //Gère les option concernant les matières
    //Lorsqu'on change de produit
    const handleMatiere = (productId) => {
        axios.get("/essai/" + productId)
            .then(function (response) {
                const data = response.data;
                const matieres = data.matPremieres;

                matieresHTML.innerHTML = "";
                matieres.forEach((matiere) => {
                    const option = createSelectOption(matiere.id, matiere.name);
                    matieresHTML.appendChild(option);
                    cache[matiere.id] = matiere.grades;
                });

                const grades = matieres[0].grades;
                gradesHTML.innerHTML = "";
                grades.forEach((grade) => {
                    const option = createSelectOption(grade.id, grade.name);
                    gradesHTML.appendChild(option);
                });
            }).catch(function (error) {
            console.error(error)
        });
    };

    //Gère les option concernant les grades des matières
    //Lorsqu'on change de matière
    const handleGrade = (matiereId) => {
        const grades = cache[matiereId];
        gradesHTML.innerHTML = "";
        grades.forEach((grade) => {
            const option = createSelectOption(grade.id, grade.name);
            gradesHTML.appendChild(option);
        });
    };

    //Gère l'evenement change du select de marière
    inputProductsHTML.addEventListener("change", e => {
        const target = e.currentTarget;
        const id = target.value;
        handleMatiere(id);
    }, false);

    //Gère l'evenement change du select de grade
    matieresHTML.addEventListener("change", (e) => {
        const target = e.currentTarget;
        const id = target.value;
        handleGrade(id);
    }, false);

    //Le tout commence par le premier porduit
    handleMatiere(1);
}, false);

